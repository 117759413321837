<template>
  <div id="data-list-view" class="data-list-container">
    <vs-table noDataText="Aucune donnée" pagination max-items="15" search :data="ordonnance">
      <div slot="header">

        <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

          <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
            <span class="mr-2 leading-none">Actions</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <vs-dropdown-menu>
            <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>CSV</span>
              </span>
            </vs-dropdown-item>

          </vs-dropdown-menu>
        </vs-dropdown> -->
        <vs-button class="mb-4 md:mb-0" @click="$router.push(`/apps/ordonnance/ordonnance-add/${clientId}`)">Nouvelle ordonnance</vs-button>

      </div>

      <template slot="thead">
        <vs-th sort-key="_id">
          N°
        </vs-th>
        <vs-th sort-key="nomOpp">Réference Ordonnance</vs-th>
        <vs-th sort-key="entreprise">Prescripteur</vs-th>
        <vs-th sort-key="etape">Date Ordonnance</vs-th>
        <vs-th sort-key="montant">Valide</vs-th>
        <vs-th>Action</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr]">
                {{indextr + 1}}
              </vs-td>
              <vs-td >
                <p class="ordonnance-RefOrd font-medium truncate hover:cursor-pointer"
                   @click="this.$router.push(`apps/opp/OpportunityView/${indextr}`)">{{ tr.RefOrd }}</p>
              </vs-td>

              <vs-td>
                <p class="ordonnance-prescripteur">{{ getPrescripteurName(tr.Prescripteur) }}</p>
              </vs-td>

              <vs-td>
                <p class="product-price">{{ tr.DateOrd | moment("calendar", "July 10 2011") }}</p>
              </vs-td>

              <vs-td>
                <template v-if="tr.active == true">
                  <vs-chip transparent color="success">
                    Oui
                  </vs-chip>

                </template>
                <template v-else>
                  <vs-chip transparent color="danger">
                    Non
                  </vs-chip>
                </template>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon title="Voir" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-grey stroke-current ml-4" />
                <feather-icon title="Editer" icon="Edit3Icon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click.stop="editData(tr)" />
                <feather-icon title="Supprimer" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="confirmDeleteRecord(tr)" />
              </vs-td>

            </vs-tr>
          </tbody>
        </template>
    </vs-table>
  </div>
</template>
<script>
export default {
  name: 'ordonnance_client',
  props: ['clientId', 'ordonnance', 'prescripteurs'],
  components: {},
  data () {
    return {
      selected: [],
      currentRefOrd: ''
    }
  },
  computed: {},
  methods: {
    getPrescripteurName (id) {
      const prescripteur = this.prescripteurs.find((p) => p._id === id) ? this.prescripteurs.find((p) => p._id === id) : ''
      return `${prescripteur.NomPresc} ${prescripteur.PrenomPresc}`
    },
    editData (data) {
      this.$router.push(`/apps/ordonnance/ordonnance-edit/${this.clientId}/${data.RefOrd}`).catch((err) => { console.log(err) })
    },
    confirmDeleteRecord (data) {
      this.currentRefOrd = data.RefOrd
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Ordonnance suppression',
        text: `Vous allez supprimer l'ordonnace "${data.RefOrd}"`,
        acceptText: 'Supprimer',
        accept: this.deleteData
      })
    },
    deleteData () {
      const payload = {
        clientId: this.clientId,
        refOrd: this.currentRefOrd
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('client/removeOrdonnance', payload)
        .then(() => {
          this.$vs.notify({
            title: 'Ordonnance Supprimer',
            text: 'supprimer!',
            iconPack: 'feather',
            icon: 'icon-trash',
            color: 'danger'
          })
          this.getClientById()
          this.$vs.loading.close()
        })
        .catch(err => { console.error(err) })
    },
    getClientById () {
      this.$store.dispatch('client/getClientById', this.clientId)
        .then(() => {

        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  created () {}
}
</script>
