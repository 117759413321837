<template>
    <div>
      <vs-table noDataText="Aucune donnée" pagination max-items="15" search :data="historiques">
        <!-- <div slot="header">
          <vs-button class="mb-4 md:mb-0" @click="$router.push(`/apps/proformaclient/add/${clientId}`)">Nouvelle proforma</vs-button>
        </div> -->

        <template slot="thead">
          <vs-th sort-key="_id">
            N°
          </vs-th>
          <vs-th>Date</vs-th>
          <vs-th>Anniv.</vs-th>
          <vs-th>Cmmd.</vs-th>
          <vs-th>Prof.</vs-th>
          <vs-th>Verre.</vs-th>
          <vs-th>Montage.</vs-th>
          <vs-th>Livraison.</vs-th>
          <vs-th sort-key="message">Message</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                {{indextr + 1}}
              </vs-td>
              <vs-td>
                {{ tr.createdAt | moment("calendar", "July 10 2011") }}
              </vs-td>
              <vs-td>
                <template v-if="tr.annivClient">
                  <vs-chip transparent color="primary">
                    oui
                  </vs-chip>
                </template>
                <template v-else>
                  <vs-chip transparent color="danger">
                    <span>non</span>
                  </vs-chip>
                </template>
              </vs-td>
              <vs-td>
                <template v-if="tr.annivCommande">
                  <vs-chip transparent color="primary">
                    oui
                  </vs-chip>
                </template>
                <template v-else>
                  <vs-chip transparent color="danger">
                    <span>non</span>
                  </vs-chip>
                </template>
              </vs-td>
              <vs-td>
                <template v-if="tr.proformat">
                  <vs-chip transparent color="primary">
                    oui
                  </vs-chip>
                </template>
                <template v-else>
                  <vs-chip transparent color="danger">
                    <span>non</span>
                  </vs-chip>
                </template>
              </vs-td>
              <vs-td>
                <template v-if="tr.livraisonVerre">
                  <vs-chip transparent color="primary">
                    oui
                  </vs-chip>
                </template>
                <template v-else>
                  <vs-chip transparent color="danger">
                    <span>non</span>
                  </vs-chip>
                </template>
              </vs-td>
              <vs-td>
                <template v-if="tr.montageTerminer">
                  <vs-chip transparent color="primary">
                    oui
                  </vs-chip>
                </template>
                <template v-else>
                  <vs-chip transparent color="danger">
                    <span>non</span>
                  </vs-chip>
                </template>
              </vs-td>
              <vs-td>
                <template v-if="tr.livraisonEquipement">
                  <vs-chip transparent color="primary">
                    oui
                  </vs-chip>
                </template>
                <template v-else>
                  <vs-chip transparent color="danger">
                    <span>non</span>
                  </vs-chip>
                </template>
              </vs-td>
              <vs-td>
                {{ tr.message }}
              </vs-td>

              <vs-td>
                
              </vs-td>

            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </div>
</template>
<script>
export default {
  name: 'sms_marketing',
  props: ['clientId'],
  data () {
    return {
      historiques: []
    }
  },
  methods: {
    getHistoriqueSMSSendedByClientId () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('sms_marketing/getHistoriqueSMSSendedByClientId', this.clientId)
        .then((response) => {
          this.historiques = response.data.historiques
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    }
  },
  created () {
    this.getHistoriqueSMSSendedByClientId()
  }
}
</script>