
<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card class="mb-2">
        <!-- Avatar -->
        <div class="vx-row">
          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4">
              <template v-if="client !== null ? client.photo : false">
                <img :src="client.photo" class="rounded w-full" />
              </template>
              <template v-else>
                <vs-avatar color="danger" size="100px" :text="`${client !== null ? client.Nom : ''} ${client !== null ? client.Prenoms : ''}`" style="font-size: 50px" />
              </template>
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-3" >
            <table>
              <tr>
                <td class="font-semibold">Civilité: </td>
                <td>{{client !== null ? client.Civilite : ''}}. </td>
              </tr>
              <tr>
                <td class="font-semibold">Nom: </td>
                <td>{{client !== null ? client.Nom : ''}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Prénoms: </td>
                <td>{{client !== null ? client.Prenoms : ''}} </td>
              </tr>
              <tr>
                <td class="font-semibold">Alias:</td>
                <td>{{ client !== null ? client.Alias : ''}} </td>
              </tr>
              <tr>
                <td class="font-semibold">Anniv:</td>
                <td>{{ client !== null ? client.DateNaiss : null | moment("calendar", "July 10 2011") }} </td>
              </tr>
              <tr>
                <td class="font-semibold">Profession: </td>
                <td>{{ client !== null ? client.Profession : ''}} </td>
              </tr>
              <tr>
                <td class="font-semibold">Langue: </td>
                <td>{{ client !== null ? client.Langue : ''}} </td>
              </tr>
              <tr>
                <td class="font-semibold">Extra: </td>
                <td>{{ client !== null ? client.Activitextra : ''}} </td>
              </tr>


            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-3">
            <table>
              <tr>
                <td class="font-semibold">Adresse:</td>
                <td>{{ client !== null ? client.Adresse.adresse : ''}} </td>
              </tr>

              <tr>
                <td class="font-semibold">Email:</td>
                <td>{{ client !== null ? client.Adresse.email : ''}} </td>
              </tr>
              <tr>
                <td class="font-semibold">Tél:</td>
                <td>{{ client !== null ? client.Adresse.tel : ''}} </td>
              </tr>
              <tr>
                <td class="font-semibold">Mobile:</td>
                <td>{{ client !== null ? client.Adresse.mobile : ''}} </td>
              </tr>
              <tr>
                <td class="font-semibold">CP:</td>
                <td>{{ client !== null ? client.Adresse.cp : ''}} </td>
              </tr>
              <tr>
                <td class="font-semibold">Pays:</td>
                <td>{{ client !== null ? client.Adresse.pays : ''}} </td>
              </tr>
              <tr>
                <td class="font-semibold">Ville:</td>
                <td>{{ client !== null ? client.Adresse.ville : ''}} / {{ client !== null ? client.Adresse.quartier : ''}}</td>
              </tr>
              <tr>
                <td class="font-semibold">Localité:</td>
                <td>{{ client !== null ? client.Adresse.localite : ''}} </td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 2 -->

          <!-- Information - Col 3 -->
          <div class="vx-col flex-1" id="account-info-col-3">
            <table>
              <tr>
                <td class="font-semibold">Assuré: </td>
                <td>

                  <template v-if="client !== null ? client.Assure == true : null">
                    <vs-chip transparent color="success">
                      Oui
                    </vs-chip>

                  </template>
                  <template v-else>
                    <vs-chip transparent color="danger">
                      Non
                    </vs-chip>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">Carte fidelité: </td>
                <td>
                  <template v-if="client !== null ? client.CarteFidelite : null">
                    <vs-chip transparent color="success">
                      Oui
                    </vs-chip>
                  </template>
                  <template v-else>
                    <vs-chip transparent color="danger">
                      Non
                    </vs-chip>
                  </template>
                </td>
              </tr>

              <tr>
                <td class="font-semibold">DCD: </td>
                <td>
                  <template v-if="client !== null ?  client.DCD == true : null">
                    <vs-chip transparent color="success">
                      Oui
                    </vs-chip>

                  </template>
                  <template v-else>
                    <vs-chip transparent color="danger">
                      Non
                    </vs-chip>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">SMS: </td>
                <td>
                  <template v-if="client !== null ?  client.PasSMS == true : null">
                    <vs-chip transparent color="success">
                      Oui
                    </vs-chip>

                  </template>
                  <template v-else>
                    <vs-chip transparent color="danger">
                      Non
                    </vs-chip>
                  </template>
                </td>
              </tr>
              <tr>
                <td class="font-semibold">Mail: </td>
                <td>
                  <template v-if="client !== null ? client.PasMail == true : null">
                    <vs-chip transparent color="success">
                      Oui
                    </vs-chip>

                  </template>
                  <template v-else>
                    <vs-chip transparent color="danger">
                      Non
                    </vs-chip>
                  </template>
                </td>
              </tr>
            </table>
          </div>
          <!-- /Information - Col 3 -->
        </div>
        <div class="vx-row">
          <div class="vx-col w-full flex ml-4 mt-8" id="account-manage-buttons">
            <vs-button
              icon-pack="feather"
              icon="icon-edit"
              class="mr-4"
              @click="updateClientShowForm"
              >Modifier</vs-button>
            <vs-button type="border" color="danger" icon-pack="feather" icon="icon-trash" @click="confirmDeleteRecord">Supprimer</vs-button>
          </div>

        </div>
      </vx-card>
    </div>

    <div class="vx-col w-full">
      <vx-card>
        <div slot="no-body" class="tabs-container px-6 pt-6">
          <vs-tabs v-model="activeTab1" class="tab-action-btn-fill-container">
            <vs-tab label="Proformas">
              <div class="tab-text">
                <proforma-client :clientId="client !== null ? client._id : null" :prescripteurs="prescripteurs" :proformas="proformaclients"></proforma-client>
              </div>
            </vs-tab>
            <vs-tab label="Ventes">
              <div class="tab-text">
                <commande-client :clientId="client !== null ? client._id : null" :prescripteurs="prescripteurs" :commandes="client !== null ? client.commandes : null"></commande-client>
              </div>
            </vs-tab>
            <vs-tab label="Prise en charge">
              <div class="tab-text">
                <prise-encharge-client :clientId="client !== null ? client._id : null" :prise_encharges="client !== null ? client.priseEnCharges : null" :societes="societes" :assurances="assurances"></prise-encharge-client>
              </div>
            </vs-tab>
            <vs-tab label="Ordonnances">
              <div class="tab-text">
                <ordonnance-client :clientId="client !== null ? client._id : null" :prescripteurs="prescripteurs"  :ordonnance="client !== null ? client.ordonnances : null"></ordonnance-client>
              </div>
            </vs-tab>
            <vs-tab label="SMS Marketing">
              <div class="tab-text">
                <sms-marketing :clientId="client !== null ? client._id : null"></sms-marketing>
              </div>
            </vs-tab>
          </vs-tabs>

        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
// Composants customs --
import OrdonnanceClient from './component/ordonnance_client.vue'
import SmsMarketing from './component/sms_marketing.vue'
import CommandeClient from './component/commande_client.vue'
import ProformaClient from './component/proforma_client.vue'
import PriseEnchargeClient from './component/prise_encharge_client.vue'

export default {
  data () {
    return {
      proformaclients: null,
      entreprise_data: null,
      entreprise_not_found: true,
      activeTab1: 0,
      activeTab: 0,

      client: null
    }
  },
  components: {
    OrdonnanceClient,
    SmsMarketing,
    CommandeClient,
    ProformaClient,
    PriseEnchargeClient
  },
  computed: {
    prescripteurs () {
      return this.$store.state.prescripteur.prescripteurs
    },
    societes () {
      return this.$store.state.societe.societes
    },
    assurances () {
      return this.$store.state.assurance.assurances
    },
    proformas () {
      return this.$store.state.proforma.proformas
    }
  },
  methods: {
    updateClientShowForm () {
      this.$router.push(`/apps/client/client-edit/${this.client._id}`).catch((err) => { console.log(err) })
    },
    confirmDeleteRecord (data) {
      this.currentClientDeletedId = data._id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmé la Suppression',
        text: `Vous allez supprimer le client  "${this.client.Nom} ${this.client.Prenoms}"`,
        accept: this.deleteRecord,
        acceptText: 'Supprimer'
      })
    },
    deleteRecord () {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('client/removeClient', this.$route.params.cliendId)
        .then(() => {
          this.showDeleteSuccess()
          this.$router.push('/apps/client/client-list')
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    showDeleteSuccess () {
      this.$vs.notify({
        color: 'danger',
        title: 'Client supprimé',
        text: 'Le client selectionné a bien été supprimée'
      })
    },
    async getClientById  () {
      if (!this.$store.getters['client/getClientById'](this.$route.params.clientId)) {
        this.$vs.loading({
          type: 'sound'
        })
        this.$store.dispatch('client/getClientById', this.$route.params.clientId)
          .then((resp) => {
            this.client = resp.data
            this.$vs.loading.close()
          })
          .catch(err => {
            console.error(err)
            this.$vs.loading.close()
          })
      } else {
        this.client = this.$store.getters['client/getClientById'](this.$route.params.clientId)
      }
    },
    getProformaByClientId () {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('proforma/getProformaByClientId', this.$route.params.clientId)
        .then((resp) => {
          this.proformaclients = resp.data
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getPrescripteurs () {
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .then(() => {

        })
        .catch(err => { console.log(err) })
    },
    getSocietes () {
      this.$store.dispatch('societe/getSocietes')
        .then(() => {
        })
        .catch((err) => { console.log(err) })
    },
    getAssurances () {
      this.$store.dispatch('assurance/getAssurances')
        .then(() => {

        })
        .catch(err => {
          console.error(err)
        })
    }
  },
  created () {
    this.getClientById()
    this.getProformaByClientId()
    this.getPrescripteurs()
    this.getAssurances()
  }
}

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-contact-view {
  table {
    td {
      vertical-align: top;
      min-width: 140px;
      padding-bottom: .8rem;
      word-break: break-all;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}

// #account-info-col-1 {
//   // flex-grow: 1;
//   width: 30rem !important;
//   @media screen and (min-width:1200px) {
//     & {
//       flex-grow: unset !important;
//     }
//   }
// }


@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }

  // #account-manage-buttons {
  //   width: 12rem !important;
  //   flex-direction: column;

  //   > button {
  //     margin-right: 0 !important;
  //     margin-bottom: 1rem;
  //   }
  // }

}

</style>
