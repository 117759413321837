<template>
  <vs-table noDataText="Aucune donnée" pagination max-items="15" search :data="commandes">
      <div slot="header">

        <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

          <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
            <span class="mr-2 leading-none">Actions</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <vs-dropdown-menu>
            <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>CSV</span>
              </span>
            </vs-dropdown-item>

          </vs-dropdown-menu>
        </vs-dropdown> -->
        <vs-button v-if="activeUserInfos.privileges.find(item => item === 'commande')&&activeUserInfos.privileges.find(item => item === 'create')" class="mb-4 md:mb-0" @click="$router.push(`/apps/commandeclient/add/${clientId}`)"> Nouvelle commande </vs-button>

      </div>

      <template slot="thead">
        <vs-th sort-key="_id">
          N°
        </vs-th>
        <vs-th sort-key="RefCmmd">Réf cmd</vs-th>
        <vs-th sort-key="DateCommd">Date Cmd</vs-th>
        <vs-th sort-key="Idprescripteur">Prescripteur</vs-th>
        <vs-th sort-key="Totalcommd">Total Cmd</vs-th>
        <vs-th sort-key="PartClient">Part Client</vs-th>
        <vs-th sort-key="PartAssu">Part Assur.</vs-th>
        <vs-th sort-key="CmdeAuFourVerre">Fourn verre</vs-th>
        <vs-th sort-key="CmdeTSalleMontage">Montage</vs-th>
        <vs-th sort-key="ComdeLivreClient">Livrer</vs-th>
        <vs-th sort-key="etat">Solde</vs-th>
        <vs-th>Action</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr]">
                {{indextr + 1}}
              </vs-td>
              <vs-td >
                <p class="ordonnance-RefOrd font-medium truncate hover:cursor-pointer"
                   @click="this.$router.push(`apps/opp/OpportunityView/${indextr}`)">{{ tr.RefCmmd }}</p>
              </vs-td>

              <vs-td>
                <p class="DateCommd">{{ tr.DateCommd | moment("calendar", "July 10 2011") }}</p>
              </vs-td>

              <vs-td>
                <p class="Idprescripteur">{{ getPrescripteurName(tr.Idprescripteur) }}</p>
              </vs-td>

              <vs-td>
                <p class="Totalcommd">{{moneyFormatter(totalNet(tr.RemiseCom, tr.PrixMont1, tr.PrixVerG1, tr.PrixVerD1, tr.PrixMont2, tr.PrixVerG2, tr.PrixVerD2, tr.PrixMont3, tr.PrixVerG3, tr.PrixVerD3)) }}</p>
              </vs-td>

              <vs-td>
                <p class="PartClient">{{ moneyFormatter(tr.PartClient) }}</p>
              </vs-td>

              <vs-td>
                <p class="PartClient">{{ moneyFormatter(tr.PartAssu) }}</p>
              </vs-td>

              <vs-td>
                <template v-if="tr.CmdeAuFourVerre">
                  <vs-chip transparent color="success">
                    Envoyer
                  </vs-chip>
                </template>
                <template v-else>
                  <vs-chip transparent color="danger">
                    Pas envoyer
                  </vs-chip>
                </template>
              </vs-td>
              <vs-td>
                <template v-if="tr.CmdeTSalleMontage">
                  <vs-chip transparent color="warning">
                    Encours
                  </vs-chip>
                </template>
                <template v-else-if="tr.CmdeMontageFin">
                  <vs-chip transparent color="success">
                    Fin
                  </vs-chip>
                </template>
                <template v-else>
                  <vs-chip transparent color="danger">
                    Pas debuter
                  </vs-chip>
                </template>
              </vs-td>
              <vs-td>
                <template v-if="tr.ComdeLivreClient">
                  <vs-chip transparent color="success">
                    Oui
                  </vs-chip>
                </template>
                <template v-else>
                  <vs-chip transparent color="danger">
                    Non
                  </vs-chip>
                </template>
              </vs-td>
              <vs-td>
                <p class="PartClient">{{ solde(tr) }}</p>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon title="Réglèment commande" icon="SendIcon" svgClasses="w-5 h-5 mr-4 hover:text-primary stroke-current" @click.stop="reglementCmmd(tr)" />
                <feather-icon title="Voir" icon="EyeIcon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="showCommande(tr.RefCmmd)" />
                <feather-icon title="Modifier" icon="Edit3Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="editData(tr.RefCmmd)" />
                <feather-icon title="Supprimer" icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-primary cursor-pointer" @click="confirmDeleteRecord(tr.RefCmmd)" />
              </vs-td>

            </vs-tr>
          </tbody>
        </template>
    </vs-table>
</template>
<script>
export default {
  props: ['clientId', 'commandes', 'prescripteurs'],
  components: {},
  data () {
    return {
      activeUserInfos: JSON.parse(localStorage.getItem('userInfo')),
      prescripteur: null,
      selected: [],
      RefCmmd: null
    }
  },
  computed: {

  },
  methods: {
    totalNet (RemiseCom, ...prices) {
      const sum = prices.reduce((acc, price) => acc + (+price || 0), 0)
      return sum - (+RemiseCom || 0)
    },
    solde (commande) {
      const totalCmmd = commande.PartClient + commande.PartAssu

      const totalRegl = commande.reglements.reduce((acc, regl) => {
        return acc + +regl.Montant
      }, 0)
      return  this.moneyFormatter((+totalCmmd - +totalRegl))
    },
    moneyFormatter (montant) {
      // la méthode toLocaleString avec l'option 'fr-FR' gérera automatiquement 
      //le formatage du montant en fonction des conventions de format françaises, 
      // en utilisant la virgule comme séparateur décimal et l'espace comme séparateur de milliers.
      return montant ? montant.toLocaleString('fr-FR') : 0
    },
    confirmDeleteRecord (RefCmmd) {
      this.RefCmmd = RefCmmd

      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Confirmation de suppression',
        text: `Voulez-vous supprimer la vente ${RefCmmd}`,
        accept: this.removeVenteByNum,
        acceptText: 'Supprimer'
      })
    },
    removeVenteByNum () {
      const payload = {
        clientId: this.clientId,
        RefCmmd: this.RefCmmd
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('client/deleteCommandeClient', payload)
        .then((resp) => {
          if (resp.data === 'Impossible de supprimer cette vente') {
            this.$vs.notify({
              title: 'error',
              text: `${resp.data}`,
              iconPack: 'feather',
              icon: 'icon-error',
              color: 'danger'
            })
            this.RefCmmd = null
            this.$vs.loading.close()
          } else {
            this.$vs.notify({
              title: 'success',
              text: 'success',
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'primary'
            })
            /* this.IdClient = null
            this.NumVente = null */
            this.RefCmmd = null
            this.$vs.loading.close()

          }
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    reglementCmmd (data) {
      this.$router.push(`/apps/caisse/${this.clientId}/${data.RefCmmd}`).catch((err) => { console.log(err) })
    },
    showCommande (RefCmmd) {
      this.$router.push(`/apps/commandeclient/detail/${this.clientId}/${RefCmmd}`).catch((err) => { console.log(err) })
    },
    editData (RefCmmd) {
      this.$router.push(`/apps/commandeclient/edit/${this.clientId}/${RefCmmd}`).catch((err) => { console.log(err) })
    },
    getPrescripteurName (id) {
      const pres = this.prescripteurs.find((p) => p._id === id) ? this.prescripteurs.find((p) => p._id === id) : ''
      return `${pres.NomPresc} ${pres.PrenomPresc ? pres.PrenomPresc : ''}`
    }
  },
  created () {}
}
</script>
