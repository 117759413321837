<template>
  <div id="data-list-view" class="data-list-container">
    <vs-popup fullscreen title="Nouvelle prise en charge" :active.sync="popupActivePriseEncharge">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <div class="vx-row">
            <div class="vx-col w-full ">
              <vx-card :noShadow="true" :cardBorder="true" title="Nouvelle société">
                <div class="vx-row">
                  <div class="vx-col w-full mt-5">
                    <span style="font-weight: bold;">NB:</span> <span style="font-style: italic;"> La section "Nouvelle société" vous permet d'ajouter une nouvelle société si elle n'existe pas dans la liste des société dans la section "Nouvelle Prise en charge"</span>
                  </div>
                  <div class="vx-col md:w-1/2">
                    <vs-input class="w-full mt-5" type="text" label="Nom Société" v-validate="'required'" name="NomSociete" :value="NomSociete" @change.native="NomSociete=$event.target.value" />
                  </div>
                  <div class="vx-col md:w-1/2">
                    <vs-input class="w-full mt-5" type="text" label="Régistre de commerce" v-validate="'required'" name="RegistreCom" :value="RegistreCom" @change.native="RegistreCom=$event.target.value" />
                  </div>
                </div>

                <div class="vx-row">
                  <div class="vx-col w-full">
                    <div class="mt-8 flex flex-wrap items-center justify-end">
                      <vs-button class="ml-auto mt-2" @click="creatSociete">ENREGISTRER</vs-button>
                      <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data_societe">ANNULER</vs-button>
                    </div>
                  </div>
                </div>
              </vx-card>
            </div>
          </div>
        </div>
        <div class="vx-col md:w-1/2">
          <vx-card :noShadow="true" :cardBorder="true" title="Nouvelle Prise en charge">
            <div class="vx-row">
              <div class="vx-col w-full">
                <vs-input class="w-full mt-4" type="text" label="N°Bon / N°BPC / N°CPC /N°Letter de caution"  name="RefPEC" :value="RefPEC" @change.native="RefPEC=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-select label="Client" autocomplete class="selectExample w-full select-large mt-4" v-model="IdClient">
                  <vs-select-item :key="index" :value="item._id" :text="`${item.Nom} ${item.Prenoms}`" v-for="(item,index) in clientsData" />
                </vs-select>

              </div>
              <div class="vx-col md:w-1/2">
                <vs-select label="Assurance" autocomplete class="selectExample w-full select-large mt-4" v-model="IdAssurance">
                  <vs-select-item :key="index" :value="item._id" :text="item.Nom_Complet" v-for="(item,index) in assuranceData" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/2">
                <vs-select label="Société" autocomplete class="selectExample w-full select-large mt-4" v-model="IdSociete">
                  <vs-select-item :key="index" :value="item._id" :text="item.NomSociete" v-for="(item,index) in Societes" />
                </vs-select>
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Assuré (e) principal(e)"  name="Assurer" :value="Assurer" @change.native="Assurer=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Matricule assuré (e) principal(e)"  name="NumSalarie" :value="NumSalarie" @change.native="NumSalarie=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Bénéficiaire"  name="Patient" :value="Patient" @change.native="Patient=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="Matricule bénéficiaire"  name="NumPatient" :value="NumPatient" @change.native="NumPatient=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="text" label="N° Police"  name="NumPolice" :value="NumPolice" @change.native="NumPolice=$even" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="number" label="Ticket Monture"  name="TickMont" :value="TickMont" @change.native="TickMont=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="number" label="Plafon monture"  name="PlafMont" :value="PlafMont" @change.native="PlafMont=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="number" label="Ticket Verre"  name="TickVerr" :value="TickVerr" @change.native="TickVerr=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="number" label="Plafon verre"  name="PlafVerr" :value="PlafVerr" @change.native="PlafVerr=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="number" label="Ticket Forfetaire"  name="TickForf" :value="TickForf" @change.native="TickForf=$event.target.value" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input class="w-full mt-4" type="number" label="Plafond Forfetaire"  name="PlafForf" :value="PlafForf" @change.native="PlafForf=$event.target.value" />
              </div>

            </div>
            <div class="vx-row">
              <div class="vx-col w-full">
                <div class="mt-8 flex flex-wrap items-center justify-end">
                  <vs-button class="ml-auto mt-2" @click="AddPriseEnCharge">ENREGISTRER</vs-button>
                  <vs-button class="ml-4 mt-2" type="border" color="warning" @click="reset_data_priseEncharge">ANNULER</vs-button>
                </div>
              </div>
            </div>

          </vx-card>
        </div>
      </div>
    </vs-popup>
    <vs-popup title="Modifier prise en charge" :active.sync="popupActiveUpdatePriseEncharge">
      <div class="vx-row">
        <div class="vx-col w-full">
          <div class="vx-row">
            <div class="vx-col w-full">
              <vs-input class="w-full mt-4" type="text" label="N°Bon / N°BPC / N°CPC /N°Letter de caution"  name="NumPolice" v-model="RefPECUpdate" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-select label="Client" autocomplete class="selectExample w-full select-large mt-4" v-model="IdClient">
                <vs-select-item :key="index" :value="item._id" :text="`${item.Nom} ${item.Prenoms}`" v-for="(item,index) in clientsData" />
              </vs-select>

            </div>
            <div class="vx-col md:w-1/2">
              <vs-select label="Société" autocomplete class="selectExample w-full select-large mt-4" v-model="IdSocieteUpdate">
                <vs-select-item :key="index" :value="item._id" :text="item.NomSociete" v-for="(item,index) in Societes" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/2">
              <vs-select label="Assurance" autocomplete class="selectExample w-full select-large mt-4" v-model="IdAssuranceUpdate">
                <vs-select-item :key="index" :value="item._id" :text="item.Nom_Complet" v-for="(item,index) in assuranceData" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" type="text" label="Assuré (e) principal(e)"  name="Assurer" :value="AssurerUpdate" @change.native="AssurerUpdate=$event.target.value" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" type="text" label="Matricule assuré (e) principal(e)"  name="NumSalarie" :value="NumSalarieUpdate" @change.native="NumSalarieUpdate=$event.target.value" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" type="text" label="Bénéficiaire"  name="Patient" :value="PatientUpdate" @change.native="PatientUpdate=$event.target.value" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" type="text" label="Matricule bénéficiaire"  name="NumPatient" :value="NumPatientUpdate" @change.native="NumPatientUpdate=$event.target.value" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" type="text" label="N° Police"  name="NumPolice" :value="NumPoliceUpdate" @change.native="NumPoliceUpdate=$event.target.value" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" type="number" label="Ticket Monture"  name="TickMont" :value="TickMontUpdate" @change.native="TickMontUpdate=$event.target.value" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" type="number" label="Plafond monture"  name="PlafMont" :value="PlafMontUpdate" @change.native="PlafMontUpdate=$event.target.value" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" type="number" label="Ticket Verre"  name="TickVerr" :value="TickVerrUpdate" @change.native="TickVerrUpdate=$event.target.value" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" type="number" label="Plafond verre"  name="PlafVerr" :value="PlafVerrUpdate" @change.native="PlafVerrUpdate=$event.target.value" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" type="number" label="Ticket Forfetaire"  name="TickForf" :value="TickForfUpdate" @change.native="TickForfUpdate=$event.target.value" />
            </div>
            <div class="vx-col md:w-1/2">
              <vs-input class="w-full mt-4" type="number" label="Plafond Forfetaire"  name="PlafForf" :value="PlafForfUpdate" @change.native="PlafForfUpdate=$event.target.value" />
            </div>
          </div>
          <div class="vx-row">
            <div class="vx-col w-full">
              <div class="mt-8 flex flex-wrap items-center justify-center">
                <vs-button class="ml-auto mt-2" @click="UpdatePriseEnCharge">MODIFIER</vs-button>
                <vs-button class="ml-4 mt-2" type="border" color="warning" @click="popupActiveUpdatePriseEncharge=false">ANNULER</vs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-popup>
    <vs-table noDataText="Aucune donnée" pagination max-items="15" search :data="prise_encharges">
      <div slot="header">
        <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

          <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
            <span class="mr-2 leading-none">Actions</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>

          <vs-dropdown-menu>
            <vs-dropdown-item>
              <span class="flex items-center">
                <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                <span>CSV</span>
              </span>
            </vs-dropdown-item>

          </vs-dropdown-menu>
        </vs-dropdown> -->
        <vs-button class="mb-4 ml-5 md:mb-0" @click="popupActivePriseEncharge = true">Nouvelle prise en charge</vs-button>

      </div>

      <template slot="thead">
        <vs-th sort-key="_id">
          N°
        </vs-th>
        <vs-th sort-key="NumPolice">N° Bon</vs-th>
        <vs-th sort-key="IdSociete">Societe</vs-th>
        <vs-th sort-key="IdAssurance">Assurance</vs-th>
        <vs-th sort-key="PlafVerr">Plafond</vs-th>
        <vs-th>Action</vs-th>
      </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr]">
                {{indextr + 1}}
              </vs-td>

              <vs-td>
                <p class="product-price">{{ tr.RefPEC }}</p>
              </vs-td>

              <vs-td>
                <p >{{ getSocieteName(tr.IdSociete) }}</p>
              </vs-td>

              <vs-td>
                <p >{{ getAssuranceName(tr.IdAssurance) }}</p>
              </vs-td>

              <vs-td>
                <p >{{ moneyFormatter(tr.PlafForf)}}</p>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon title="Modifier" icon="Edit3Icon" svgClasses="w-5 h-5 hover:text-primary stroke-current" @click.stop="editPriseEnCharge(tr, indextr)" />
                <feather-icon title="Supprimer" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="confirmDeletePriseEnCharge(tr)" />
              </vs-td>

            </vs-tr>
          </tbody>
        </template>
    </vs-table>
  </div>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import {French as FrenchLocale} from 'flatpickr/dist/l10n/fr.js'
export default {
  name: 'prise_encharge_client',
  props: ['clientId', 'prise_encharges', 'societes', 'assurances'],
  components: {flatPickr},
  data () {
    return {
      configdateTimePicker: {
        dateFormat: 'd F Y',
        locale: FrenchLocale
      },
      idOfficine: null,
      activeUserInfos: null,
      IdClient: this.clientId,
      popupActivePriseEncharge: false,

      prescripteur: null,
      selected: [],
      RefPEC: null,
      IdSociete: null,
      IdAssurance: null,
      RefCommande: '',
      NumPolice: '',
      Assurer: '',
      NumPatient: '',
      Patient: '',
      NumSalarie: '',
      Salarie: '',
      TickMont: '',
      TickVerr: '',
      PlafMont: '',
      PlafVerr: '',
      TickForf: '',
      PlafForf: '',
      QteMont: '',
      QteVerr: '',
      QtePair: '',

      /* update prise en charge */
      popupActiveUpdatePriseEncharge: false,
      idPriseEnCharge: null,
      RefPECUpdate: null,
      IdSocieteUpdate: null,
      IdAssuranceUpdate: null,
      RefCommandeUpdate: '',
      NumPoliceUpdate: '',
      AssurerUpdate: '',
      NumPatientUpdate: '',
      PatientUpdate: '',
      NumSalarieUpdate: '',
      SalarieUpdate: '',
      TickMontUpdate: '',
      TickVerrUpdate: '',
      PlafMontUpdate: '',
      PlafVerrUpdate: '',
      TickForfUpdate: '',
      PlafForfUpdate: '',
      QteMontUpdate: '',
      QteVerrUpdate: '',
      QtePairUpdate: '',
      index: null,

      /* drop */
      RefPECDrop: null,

      /* Assurance */
      Nom_Abrege:'',
      Nom_Complet:'',
      adresse:'',
      pays:'',
      telephone:'',
      mobile:'',
      fax:'',
      Email:'',
      Site:'',
      RegistreComA:'',
      ComptCont:'',
      ComptBanq:'',
      DelaiRegl: null,

      /* Societe */
      NomSociete:'',
      RegistreCom:''
    }
  },
  computed: {
    clientsData () {
      return this.$store.state.client.clients
    },
    PaysData () {
      return this.$store.state.pays.pays
    },
    Societes () {
      return this.$store.state.societe.societes
    },
    assuranceData () {
      return this.$store.state.assurance.assurances
    }
  },
  methods: {
    moneyFormatter (montant) {
      return montant ? montant.toLocaleString('fr-FR') : 0
    },
    getSocieteName (id) {
      const name = this.societes.find((s) => s._id === id) ? this.societes.find((s) => s._id === id).NomSociete : ''

      return name
    },
    getAssuranceName (id) {
      const name = this.assurances.find((a) => a._id === id) ? this.assurances.find((a) => a._id === id).Nom_Complet  : ''
      return name
    },
    AddPriseEnCharge () {
      const payload = {
        RefPEC: this.RefPEC,
        IdSociete: this.IdSociete,
        IdAssurance: this.IdAssurance,
        NumPolice: this.NumPolice,
        Assurer: this.Assurer,
        NumPatient: this.NumPatient,
        Patient: this.Patient,
        NumSalarie: this.NumSalarie,
        TickMont: this.TickMont,
        TickVerr: this.TickVerr,
        PlafMont: this.PlafMont,
        PlafVerr: this.PlafVerr,
        TickForf: this.TickForf,
        PlafForf: this.PlafForf,
        id: this.IdClient
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('client/addPriseEnCharge', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.reset_data_priseEncharge()
          this.popupActivePriseEncharge = false
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data_priseEncharge () {
      this.RefPEC = null
      this.IdSociete = null
      this.IdAssurance = null
      this.RefCommande = null
      this.NumPolice = null
      this.Assurer = null
      this.NumPatient = null
      this.Patient = null
      this.NumSalarie = null
      this.Salarie = null
      this.TickMont = null
      this.TickVerr = null
      this.PlafMont = null
      this.PlafVerr = null
      this.TickForf = null
      this.PlafForf = null
    },
    editPriseEnCharge (data, index) {
      this.index = index
      this.idPriseEnCharge = data._id
      this.RefPECUpdate = data.RefPEC
      this.IdSocieteUpdate = data.IdSociete
      this.IdAssuranceUpdate = data.IdAssurance
      this.NumPoliceUpdate = data.NumPolice
      this.AssurerUpdate = data.Assurer
      this.NumPatientUpdate = data.NumPatient
      this.PatientUpdate = data.Patient
      this.NumSalarieUpdate = data.NumSalarie
      this.SalarieUpdate = data.Salarie
      this.TickMontUpdate = data.TickMont
      this.TickVerrUpdate = data.TickVerr
      this.PlafMontUpdate = data.PlafMont
      this.PlafVerrUpdate = data.PlafVerr
      this.TickForfUpdate = data.TickForf
      this.PlafForfUpdate = data.PlafForf

      this.popupActiveUpdatePriseEncharge = true
    },
    UpdatePriseEnCharge () {
      const payload = {
        RefPEC: this.RefPECUpdate,
        IdSociete: this.IdSocieteUpdate,
        IdAssurance: this.IdAssuranceUpdate,
        NumPolice: this.NumPoliceUpdate,
        Assurer: this.AssurerUpdate,
        NumPatient: this.NumPatientUpdate,
        Patient: this.PatientUpdate,
        NumSalarie: this.NumSalarieUpdate,
        Salarie: this.SalarieUpdate,
        TickMont: this.TickMontUpdate,
        TickVerr: this.TickVerrUpdate,
        PlafMont: this.PlafMontUpdate,
        PlafVerr: this.PlafVerrUpdate,
        TickForf: this.TickForfUpdate,
        PlafForf: this.PlafForfUpdate,
        id: this.IdClient,
        idPriseEnCharge: this.idPriseEnCharge,
        index: this.index
      }

      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('client/updatePriseEnCharge', payload)
        .then(() => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.idPriseEnCharge = null
          this.popupActiveUpdatePriseEncharge = false
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    confirmDeletePriseEnCharge (data) {
      this.RefPECDrop = data.RefPEC
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Prise en charge suppression',
        text: 'Vous allez supprimer cette prise en charge',
        acceptText: 'Supprimer',
        accept: this.deletePriseEnCharge
      })
    },
    deletePriseEnCharge () {
      const payload = {
        RefPEC: this.RefPECDrop,
        id: this.IdClient
      }
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('client/deletePriseEnChargeClient', payload)
        .then((resp) => {
          if (!resp.data.message) {
            this.$vs.notify({
              title: 'success',
              text: 'success',
              iconPack: 'feather',
              icon: 'icon-check',
              color: 'primary'
            })
            this.$vs.loading.close()
          } else {
            this.$vs.notify({
              title: 'Alerte',
              text: `${resp.data.message}`,
              iconPack: 'feather',
              icon: 'icon-alert-triangle',
              color: 'danger'
            })
            this.$vs.loading.close()
          }
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    creatSociete () {
      const payload = {
        NomSociete: this.NomSociete,
        RegistreCom: this.RegistreCom
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('societe/addSociete', payload)
        .then((response) => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.IdSociete = response.data._id
          //this.popupUser = false
          this.reset_data_societe()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data_societe () {
      this.NomSociete = ''
      this.RegistreCom = ''
    },
    addAssurance () {
      const payload = {
        Nom_Abrege: this.Nom_Abrege,
        Nom_Complet: this.Nom_Complet,
        adresse: this.adresse,
        pays: this.pays,
        telephone: this.telephone,
        mobile: this.mobile,
        fax        :this.fax,
        Email : this.Email,
        Site  : this.Site,
        RegistreCom: this.RegistreComA,
        ComptCont  : this.ComptCont,
        ComptBanq: this.ComptBanq,
        DelaiRegl: this.DelaiRegl
      }
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('assurance/addAssurance', payload)
        .then((response) => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.IdAssurance = response.data._id
          this.reset_data_assurance()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data_assurance () {
      this.Nom_Abrege = ''
      this.Nom_Complet = ''
      this.adresse = ''
      this.pays = ''
      this.telephone = ''
      this.mobile = ''
      this.fax = ''
      this.Email = ''
      this.Site = ''
      this.RegistreComA = ''
      this.ComptCont = ''
      this.ComptBanq = ''
      this.DelaiRegl = ''
    },
    getPays () {
      this.$store.dispatch('pays/getPays')
        .then(() => { })
        .catch((err) => { console.log(err) })
    },
    getSocietes () {
      this.$store.dispatch('societe/getSocietes')
        .then(() => {
        })
        .catch((err) => { console.log(err) })
    },
    getAssurances () {
      this.$store.dispatch('assurance/getAssurances')
        .then(() => {

        })
        .catch(err => {
          console.error(err)
        })
    },
    getClientsOfficine (id) {
      this.$store.dispatch('client/getClientsOfficine', id)
        .then((response) => {
          console.log(response.data)
        })
        .catch((error) => { console.log(error) })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.idOfficine = this.activeUserInfos.IdOfficine

      this.getClientsOfficine(this.idOfficine)
    }
  },
  created () {
    this.getPays()
    this.getSocietes()
    this.getAssurances()
    this.activeUserInfo()
  }
}
</script>
