<template>
  <div id="data-list-view" class="data-list-container">
    <template v-if="proformas">
      <vs-table noDataText="Aucune donnée" pagination max-items="15" search :data="proformas">
        <div slot="header">
          <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

            <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
              <span class="mr-2 leading-none">Actions</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>CSV</span>
                </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown> -->
          <vs-button class="mb-4 md:mb-0" @click="$router.push(`/apps/proformaclient/add/${clientId}`)">Nouvelle proforma</vs-button>
        </div>

        <template slot="thead">
          <vs-th sort-key="_id">
            N°Proforma
          </vs-th>
          <vs-th sort-key="Idprescripteur">Prescripteur</vs-th>
          <vs-th sort-key="proposition1">Prix Proposition 1</vs-th>
          <vs-th sort-key="proposition2">Prix Proposition 2</vs-th>
          <vs-th sort-key="proposition2">Prix Proposition 3</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{data}">
          <tbody>
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                {{tr.numProforma}}
              </vs-td>

              <vs-td>
                <p class="ordonnance-prescripteur">{{ tr.Idprescripteur ? getPrescripteurName(tr.Idprescripteur) : '' }}</p>
              </vs-td>

              <vs-td>
                <p class="product-price">{{ propositionUn(tr) }}</p>
              </vs-td>

              <vs-td>
                <p class="date">{{ propositionDeux(tr) }}</p>
              </vs-td>

              <vs-td>
                <p class="date">{{ propositionTrois(tr)}}</p>
              </vs-td>

              <vs-td class="whitespace-no-wrap">
                <feather-icon title="Details" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-grey stroke-current ml-4" @click.stop="showProforma(tr._id)" />
                <feather-icon title="Editer" icon="Edit3Icon" svgClasses="w-5 h-5 hover:text-primary stroke-current ml-2" @click.stop="editProforma(tr._id)" />
                <feather-icon title="Supprimer" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="confirmDeleteRecord(tr._id)" />
              </vs-td>

            </vs-tr>
          </tbody>
        </template>
      </vs-table>
    </template>
  </div>
</template>
<script>
export default {
  name: 'proforma_client',
  props: ['clientId', 'proformas', 'prescripteurs'],
  components: {},
  data () {
    return {
      selected: [],
      idOfficine: null,
      activeUserInfos: null,
      idProforma: null
    }
  },
  computed: {
    officines () {
      return this.$store.state.officine.officines
    }
  },
  methods: {
    editProforma (id) {
      this.$router.push(`/apps/proforma-client/edit/${id}`).catch((err) => { console.log(err) })
    },
    showProforma (id) {
      this.$router.push(`/apps/proforma-client/details/${id}`).catch((err) => { console.log(err) })
    },
    confirmDeleteRecord (id) {
      this.idProforma = id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Proforma suppression',
        text: 'Vous allez supprimer la proforma selectionnée',
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('proforma/removeProforma', this.idProforma)
        .then(() => {
          this.$vs.notify({
            title: 'Proforma Supprimer',
            text: 'supprimer!',
            iconPack: 'feather',
            icon: 'icon-trash',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((error) => {
          console.log(error)
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.idOfficine = this.activeUserInfos.IdOfficine

      /* this.getStockByOfficine(this.idOfficine) */
    },
    getPrescripteurName (id) {
      const pres = this.prescripteurs.find((p) => p._id === id) ? this.prescripteurs.find((p) => p._id === id) : ''
      return `${pres.NomPresc} ${pres.PrenomPresc ? pres.PrenomPresc : ''}`
    },
    calculerPrix (data, prixKey) {
      const PrixBrute = (data[`PrixMont${prixKey}`] ? +data[`PrixMont${prixKey}`] : 0) + ((data[`PrixVerG${prixKey}`] ? +data[`PrixVerG${prixKey}`] : 0) + (data[`PrixVerD${prixKey}`] ? +data[`PrixVerD${prixKey}`] : 0))
      const montantRemise = ((data.RemiseCom ? +data.RemiseCom : 0) * PrixBrute) / 100
      const total = PrixBrute - montantRemise
      /* Formatage du calcul */

      return total ? total.toLocaleString('fr-FR') : 0
    },

    propositionUn (data) { return this.calculerPrix(data, 1) },
    propositionDeux (data) { return this.calculerPrix(data, 2) },
    propositionTrois (data) { return this.calculerPrix(data, 3) }

  },
  created () {}
}
</script>
